<!DOCTYPE html>
<html lang="en">


<head>
  <meta charset="UTF-8">
  <meta name=viewport content="width=device-width, initial-scale=1">
  <link href="https://fonts.googleapis.com/css2?family=Athiti:wght@500&family=Pattaya&display=swap" rel="stylesheet">
  <title>Document</title>
</head>


<body class="ht">
  <div #back></div>
  <div id="bottom"  >
    <a href="#1"><i class="fa fa-chevron-circle-up button-top" ></i></a>
   </div>
 
   <div class="card2"><br><br>
      <span>
          <h1>{{nameForm}}</h1> 
      </span>
      <span class="example-spacer"></span>

    <div class="container">
        <div class="card2"><br>
            <div class="row leftRight">
                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblCode}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="itemCode" name="itemCode" [(ngModel)]="m_datas.keycode" class="form-control">           
                    </div>
                </div>

                <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblName}}</label> </div>
                <div class="col-md-3 mb-3"> 
                    <div class="input-group">
                        <input  type="text" id="itemName" name="itemName" [(ngModel)]="m_datas.keyname" class="form-control">           
                    </div>
                </div>
                <div class="col-md-2 mb-2 fontCenter">
                    <button (click) = "queryData()" class = "btn btn-success text-center">ค้นหา</button>&nbsp;
                    <button class = "btn btn-success text-center">เครียร์</button>
                </div>
            </div>
        </div>
        
    </div>
    <br>
        <button (click) = "addData()"  class = "btn btn-success text-center">เพิ่มข้อมูล</button>
    <div class="container-fluid ">
      <div class="card2">

      </div>
      <div class="card2">
          <div class="row">
            <div class="col-sm-12">
              <table id="dtHorizontalExample" class="table table-hover table-bordered table-sm" cellspacing="0" width="100%" >
                <thead>
                    <tr class="text-center">
                        <th scope="col" style="text-align: center;"> แก้ไข</th>
                        <th scope="col" style="text-align: center;"> Id</th>
                        <th scope="col" style="text-align: center;"> {{lblCode}}</th>
                        <th scope="col" style="text-align: center;"> {{lblName}}</th>
                        <th scope="col" style="text-align: center;"> ลบ </th>
                    </tr>
                </thead>
                <tbody>
                    <tr  *ngFor = "let item of s_data" >
                        <td scope="row" class="col-1 fontCenter">  <button (click) = "updateData(item.id)" class = "btn btn-info text-center buttonRow"> แก้ไข</button></td>
                        <td scope="row" class="col-1 fontRight"> {{item.id}}</td>
                        <td scope="row" class="col-1 fontLeft"> {{item.keycode}}</td>
                        <td scope="row" class="col-2 fontLeft"> {{item.keyname}} </td>
                        <td scope="row" class="col-1 fontCenter"><button (click) = "deleteData(item.id)" class = "btn btn-danger text-center buttonRow"> ลบ</button></td>
                    </tr>
                  
                </tbody>
            </table>
        </div>
      </div>
      
    </div>
    
    <br>
    </div>
   </div>

<!-- Creates the bootstrap modal where the image will appear -->
<div [style.display]="showModal ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <form (ngSubmit)="onSubmit(eventsFlag)">
    <div class="modal-dialog " id="exampleModalScrollable" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable " role="document">
       <div class="modal-content">
          
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalScrollableTitle"><h2> {{eventsText}} </h2></h5>
            <button style="text-align:center; width:10%; padding:5px;"  class="btn btn-outline-danger" type="button"  data-dismiss="modal" (click)="hide()">X</button>
          </div>

          
            <div class="modal-body">
                <div class="card2"><br>
                   <div class="row leftRight">
                        <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblCode}}</label> </div>
                         <div class="col-md-4 mb-4"> 
                            <div class="input-group">
                                <input  type="text" id="keycode" name="keycode" [(ngModel)]="m_data.keycode" class="form-control">           
                            </div>
                         </div>

                         <div class="col-md-2 mb-2"><label class="verticalLine" for="">{{lblName}}</label> </div>
                         <div class="col-md-4 mb-4"> 
                            <div class="input-group">
                                <input  type="text" id="keyname" name="keyname" [(ngModel)]="m_data.keyname" class="form-control">           
                            </div>
                         </div>
                    </div>
                </div>
            </div>

          <div class="modal-footer">
            <button style="text-align:center; width:20%;  padding: 5px;" class="btn btn-outline-success" type="submit">Save</button>
            <button style="text-align:center; width:20%; padding:5px;"  class="btn btn-outline-success" type="button"  data-dismiss="modal" (click)="hide()">Close</button>
          </div>
       
        </div>
      </div>
    </div>
   </form>
  </div>
    <!-- end  Creates the bootstrap modal where the image will appear -->

</body>