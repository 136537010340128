export class c_masterRef{
    id : number=0;
    keycode: string="";
    keyname: string="";
    parentid : number =0;
    masterarea : number =0;
    flag1: string="";
    lockeditflag: string="";
    lockdeleteflag: string="";
    keyaccess: string="";
    keyaccess_group: string="";
    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";
}