<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">

  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <link href="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/css/footer.component.css" rel="stylesheet"
    id="footer.component.css">
  <script src="//maxcdn.bootstrapcdn.com/bootstrap/3.3.0/js/bootstrap.min.js"></script>
  <script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
  <link href="//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet">
  <link href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/footer.component.css" rel="stylesheet"
    integrity="sha256-MfvZlkHCEqatNoGiOXveE8FIwMzZg4W85qfrfIFBfYc= sha512-dTfge/zgoMYpP7QbHy4gWMEGsbsdZeCXz7irItjcC3sPUFtf0kuFbDz/ixG7ArTxmDjLXDmezHubeNikyKGVyQ=="
    crossorigin="anonymous">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Kanit:wght@200;300&display=swap" rel="stylesheet">
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link
    href="https://fonts.googleapis.com/css2?family=Kanit:wght@200;300&family=Londrina+Solid:wght@900&family=Oswald:wght@500&display=swap"
    rel="stylesheet">
  <title>Document</title>
</head>

<body>
  <!-- Footer -->

  <footer class="page-footer lighten-5">

    <div class="container text-center text-md-left mt-5">
      
      <div class="row mt-4 dark-grey-text">

        <div class="col-md-3">
          <a class="col-md-2" href="#">
            <!-- <img width="250px" height="230px" src="assets\img\logo\logo.png" alt="logo"> -->
            <!-- <img width="180px" height="100px" src="http://mittraphapservice.dyndns.info:8081/api/v1/files/logo-png.png" alt="logo"> -->
            <!-- <img width="180px" height="90px" src="http://www.websiteservice.mittraphap.co.th:8081/api/v1/files/logo.png" alt="logo"> -->
            <img width="180px" height="90px" src={{logoPath}} alt="logo">
          </a>

        </div>

        <div class="col-md-3 footer-border">
          <h5 class="text-uppercase font-weight-bold">สำนักงานใหญ่</h5><br>
          <h6>บริษัท มิตรภาพโภคภัณฑ์ จำกัด (มหาชน)</h6>
          <h6>11 ซ.รามคำแหง 81/1 ถ.รามคำแหง แขวงหัวหมาก</h6>
          <h6> เขตบางกะปิ กทม. 10240</h6>
          <h6> mittraphap@mittraphap.co.th</h6>

        </div>

        <div class="col-md-3">

          <h5 class="text-uppercase font-weight-bold">MITTRAPHAP CONTACT </h5><br>

          <h3>02-7322448-57</h3><br>
          <h5>(Fax : 02-377-9246)</h5>

        </div>
        <div class="col-md-3">
          <h5 class="text-uppercase font-weight-bold">ช่องทางการติดต่อ</h5><br>
          <a href="https://www.facebook.com/mittraphapmm" target="_blank"><i style="color:rgb(252, 253, 251)" class="fa fa-facebook-square fa-3x social social-fb"></i></a>
          <a href="#"><i style="color:rgb(252, 253, 251)" class="fa fa-google-plus-square fa-3x social social-gp"></i></a>
          <a href="#"><i style="color:rgb(252, 253, 251)" class="fa fa-envelope-square fa-3x social social-em"></i></a>

        </div>

      </div>
      
    </div><br>
    <a class="text" href="http://www.mittraphap.co.th/">
     <p>© 2021 Copyright: MITTRAPHAP.CO.TH </p>
    </a>
     <div class="container">
     </div>
  </footer>
</body>

</html>