
import { Component, OnInit } from '@angular/core';
import { c_persons } from '../../app.class/c_persons';
import { s_persons } from '../../app.service/s_persons';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GlobalConstants } from '../../common/global-constants';
import { UploadFilesService } from 'src/app/app.service/upload-files.service';
import { Observable } from 'rxjs';
import { filedata } from 'src/app/app.class/filedata';

@Component({
  selector: 'app-frm-persons',
  templateUrl: './frm-persons.component.html',
  styleUrls: ['./frm-persons.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class FrmPersonsComponent implements OnInit {
  s_data: c_persons[]=new Array();//ไว้สำหรับนำมาแสดง
  m_data: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  m_datas: c_persons = new c_persons();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข
  id: number=0 ;
  isLoggedIn: boolean=true;
  eventsFlag : string ="A";
  eventsText : string="";
  role1 : boolean= false;
  BlogId : number=0;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileInfos: Observable<any> | undefined;
  t_fileInfos : filedata[] =new Array();
  file: filedata = new filedata();

  httpPart : string ="";


  itemCode : string="";
  itemName : string="";
  itemDesc : string="";
  persontype : number=0;
  nameForm : string="";

  lblId : string="Id";
  lblCode : string="รหัส";
  lblName : string="ชื่อ";
  lblDesc : string="รายละเอียด";
  lblTax : string="เลขที่ผู้เสียภาษี";
  lblAddress1 : string="ที่อยู่1";
  lblAddress2 : string="ที่อยู่2";


  constructor(private dataService: s_persons,
    private router: Router,private formBuilder: UntypedFormBuilder,private route: ActivatedRoute,private uploadService: UploadFilesService) {
      
    }

   ngOnInit(): void {
      this.role1=GlobalConstants.role1;
      this.httpPart=GlobalConstants.httpPath;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.persontype = this.route.snapshot.params['id'];

      this.nameForm = this.route.snapshot.params['name'];
      this.lblCode=this.lblCode+ this.nameForm;
      this.lblName=this.lblName+ this.nameForm;
  
      this.queryData();
   }

   queryData(){
    this.getData(this.persontype,this.m_datas);
   }

   private getData(id? : number,mr? : c_persons){
    this.dataService.getDataList(id,mr).subscribe(data => {
        this.s_data=data;
    });
  }

  addData(){
    this.m_data = new c_persons();
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="A"; 
    this.eventsText ="เพิ่มข้อมูล " + this.nameForm;
  }

   updateData(id: number){
     this.id=id;
     this.dataService.getDataById(this.id).subscribe(data => {
       this.m_data = data;
     }, error => console.log(error));
    this.showModal = true; // Show-Hide Modal Check
    this.eventsFlag="E";
    this.eventsText ="แก้ไขข้อมูล " + this.nameForm;
   }
 
   deleteData(id: number,blogId?: number,fileId1? : string,fileId2? : string ){
    let isConfirm : Boolean | any ;
    isConfirm=confirm("Are you sure to delete "+ id)
    if(isConfirm===false) {
      return;
    }
      this.dataService.deleteData(id).subscribe( data => {
        console.log(data);
        this.queryData();
    
      })
      this.eventsFlag="D";
    
  }

 //for Submit form when action
   onSubmit(AddEditMode : string){
   
     if (AddEditMode=="A") {
       this.m_data.persontype=this.persontype;
       this.dataService.createData(this.m_data).subscribe( data =>{
         console.log(data);

         this.queryData();
       },
       error => console.log(error));
     }else if (AddEditMode=="E"){
       this.dataService.updateData(this.id, this.m_data).subscribe( data =>{
         
         console.log(data);
         this.queryData();
       }
       , error => console.log(error));
     }
      this.hide();
   }
 
 //for popup form
   showModal: boolean | any;
   registerForm: UntypedFormGroup | any;
   submitted = false;
 
  show()
    { 
      this.showModal = true;
    }

   hide()
   {
     this.showModal = false;
   }

   verifyUser(Us : boolean){
     GlobalConstants.role1=Us;
     this.role1=GlobalConstants.role1;
   }
   
}

