<!DOCTYPE html>
<html lang="en-US">
<head>
    <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>Navbar Right Align | Carey Development</title>
</head>

<body>
    <nav class="navbar navbar-expand-lg navbar-light fixed-top bg-secondary ">
        <div class="container-fluid ">
            <div class="bg2">
                <a class="navbar-brand-" href="">
                    <img src={{logoPath}} alt="logo" style="width: 100px;height: 100%;">
                </a>
            </div>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav justify-content-center">

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลผู้ใช้งาน</a>
                            <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                                <a class="nav-link" [routerLink]="['/user-group-component']" routerLinkActive="router-link" href="#">กลุ่มผู้ใช้งาน</a>
                                <a class="nav-link" [routerLink]="['/user-component']" routerLinkActive="router-link" href="#">บัญชีผู้ใช้งาน</a>
                            </div>
                    </li>


                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลหลัก</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <li class=""  href="">ทั่วไป</li>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_country,'ประเทศ']" routerLinkActive="router-link" href="#">ประเทศ</a>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_supType,'ประเภทผู้ขาย']" routerLinkActive="router-link" href="#">ประเภทผู้ขาย</a>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_cusType,'ประเภทลูกค้า']" routerLinkActive="router-link" href="#">ประเภทลูกค้า</a>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_empLevel,'ระดับพนักงาน']" routerLinkActive="router-link" href="#">ระดับพนักงาน</a>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_dept,'แผนก']" routerLinkActive="router-link" href="#">แผนก</a>
                           
                            <li class=""  href="">ทรัพย์สิน</li>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_unit,'หน่วยนับ']" routerLinkActive="router-link" href="#">หน่วยนับ</a>
                            <a class="nav-link" [routerLink]="['/frm-master-component',mr_itemType,'ประเภทสินทรัพย์']" routerLinkActive="router-link" href="#">ประเภทสินทรัพย์</a>
                            
                            <li class=""  href="">บัญชี</li>
                            <!-- <a class="nav-link" [routerLink]="['/user-group-component']" routerLinkActive="router-link" href="#">กลุ่มผู้ใช้งาน</a>
                            <a class="nav-link" [routerLink]="['/user-component']" routerLinkActive="router-link" href="#">บัญชีผู้ใช้งาน</a> -->
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลส่วนกลาง</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="nav-link" [routerLink]="['/frm-persons-component',ps_company,'บริษัท']" routerLinkActive="router-link" href="#">ข้อมูลบริษัท</a>
                            <a class="nav-link" [routerLink]="['/frm-persons-component',ps_cus,'ลูกค้า']" routerLinkActive="router-link" href="#">ข้อมูลลูกค้า</a>
                            <a class="nav-link" [routerLink]="['/frm-persons-component',ps_sup,'ผู้ขาย']" routerLinkActive="router-link" href="#">ข้อมูลผู้ขาย</a>
                            <a class="nav-link" [routerLink]="['/frm-persons-component',ps_emp,'พนักงาน']" routerLinkActive="router-link" href="#">ข้อมูลพนักงาน</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลทรัพย์สิน</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="nav-link" [routerLink]="['/frm-part-items-component',0,'ทรัพย์สิน']" routerLinkActive="router-link" href="#">ข้อมูลทรัพย์สิน</a>
                        </div>
                    </li>

                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">ข้อมูลบัญชี</a>
                        <div class="dropdown-menu dropdown-menu-center" aria-labelledby="navbarDropdown">
                            <a class="nav-link" [routerLink]="['/user-group-component']" routerLinkActive="router-link" href="#">กลุ่มผู้ใช้งาน</a>
                            <a class="nav-link" [routerLink]="['/user-component']" routerLinkActive="router-link" href="#">บัญชีผู้ใช้งาน</a>
                        </div>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/login']" routerLinkActive="router-link" href="#" (click)="exitSystem()">
                            <i *ngIf="isLoggedIn" class="fa fa-sign-out fa-1x" aria-hidden="true" (click)="logout()"  title="Log out"></i>
                            <i *ngIf="!isLoggedIn" class="fa fa-sign-in fa-1x" aria-hidden="true"  title="Log in"></i>
                        </a>
                    </li>

                    <div *ngIf="role1">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" id="servicesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{systems}}</a>
                            <div class="dropdown-menu dropdown-menu-left " aria-labelledby="servicesDropdown">
                                <div class="d-md-flex ">
                                    <div>
                                        <a class="dropdown-item" (click)="verifyUser(true)">ADMIN</a>
                                        <a class="dropdown-item" (click)="verifyUser(false)">USER</a>
                                        <a class="dropdown-item" [routerLink]="['/app-goods-popup-component']" routerLinkActive="router-link">{{goodsView}}</a>
                                        <a class="dropdown-item" [routerLink]="['/testarea-component']" routerLinkActive="router-link">{{test}}</a>
                                        <a class="dropdown-item" [routerLink]="['/testarea2-component']" routerLinkActive="router-link">{{test2}}</a>
                                    </div>
                                </div>
                            </div>
                        </li>


                    </div>
                </ul>

                <!-- <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/contact">Contact</a>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Account</a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                            <li><a class="dropdown-item" href="/login">Login</a></li>
                            <li><a class="dropdown-item" href="/register">Register</a></li>
                        </ul>
                    </li>
                </ul> -->
            </div>
        </div>
    </nav>
 </body>
</html>