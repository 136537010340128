export class c_persons{
    id : number=0;
    personcode: string="";
    personname: string="";
    cardid : string="";
    taxid : string="";
    address1 : string="";
    address2 : string="";

    personlevel: number=0;
    persontype: number=0;
    phone: string="";
    resignflag: string="";

    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";
}