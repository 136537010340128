import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_partitems } from '../app.class/c_partitems';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class s_partitems {

  private baseURL = "";
  private baseVisitURL = GlobalConstants.httpPath + "goods_count";
  private person : any;
  tP : String ="partitems";

  constructor(private httpClient: HttpClient) { 
    this.baseURL = GlobalConstants.httpPath + this.tP;
  }
  
  getDataList(id? :number,mt? : c_partitems): Observable<c_partitems[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
    
    if (id > 0){
      this.baseURL = GlobalConstants.httpPath + this.tP +"/allkey?id="+ mt.id + "&partcode=" + mt.partcode + "&partname=" + mt.partname ;
    }
    return this.httpClient.get<c_partitems[]>(`${this.baseURL}`);
  }

  createData(data: c_partitems): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_partitems>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.get<c_partitems>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_partitems): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_partitems): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

