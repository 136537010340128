export class c_partitems{
    id : number=0;
    partcode: string="";
    partname: string="";
    partdesc: string="";
    qrcode: string="";
    cancelflag: string="";
    parttypeid_id : number =0;
    unitid_id : number =0;
    createby : string ="";
    createdate :string="";
    modifyby: string="";
    modifydate :string="";
}