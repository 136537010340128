<!-- <br><br><br>
<div class="ht">
  <div class="col-md-12 ">
    <div class="card card-container">
      <img id="profile-img" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" class="profile-img-card"/>
      
    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" class="form-control" name="username" [(ngModel)]="form.username" required #username="ngModel"/>
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && username.invalid" >
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
        <div  class="alert alert-danger" role="alert" *ngIf="f.submitted && password.invalid">
          <div *ngIf="password.errors.required">Password is required</div>
          <div *ngIf="password.errors.minlength">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <br>
      <div class="form-group text-center">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
      <app-menubar></app-menubar>
      <app-goods-popup></app-goods-popup>
    </div>

  <div class="text-center">
    <a class="nav-link" [routerLink]="['/register']" routerLinkActive="router-link" href="#">Sign Up</a>
  </div>
    
  

  </div>
</div>
</div> -->


<div class="col-md-12">
  <div class="card card-container">
    <img
      id="profile-img"
      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
      class="profile-img-card"
    />
    <form
      *ngIf="!isLoggedIn"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          class="form-control"
          name="username"
          [(ngModel)]="form.username"
          required
          #username="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
        />
        <div *ngIf="username.errors && f.submitted" class="invalid-feedback">
          Username is required!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="6"
          #password="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
        />
        <div *ngIf="password.errors && f.submitted" class="invalid-feedback">
          <div *ngIf="password.errors['required']">Password is required</div>
          <div *ngIf="password.errors['minlength']">
            Password must be at least 6 characters
          </div>
        </div>
      </div>
      <div class="form-group">
        <button class="btn btn-primary btn-block">
          Login
        </button>
      </div>
      <div class="form-group">
        <div *ngIf="f.submitted && isLoginFailed" class="alert alert-danger" role="alert">
          Login failed: {{ errorMessage }}
        </div>
      </div>
    </form>
    <div class="alert alert-success" *ngIf="isLoggedIn">
      Logged in as {{ roles }}.
    </div>
  </div>
</div>