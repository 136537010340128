import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import{ GlobalConstants } from './common/global-constants';
import { TokenStorageService } from './_services/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = "";
  role1 : boolean= false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tokenStorageService: TokenStorageService) 
    {
       this.role1 = !!this.tokenStorageService.getToken();

      //  alert(this.role1);
    }

}
