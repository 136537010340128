import { Goods } from '../app.class/goods';
import { GoodsService } from '../app.service/goods.service';

export class GlobalConstants {
    public static lanq: string = "th";
    public static role1: boolean =false;
    public static role2: boolean =false;
    // public static httpPath : string ="http://localhost:8444/api/v1/";
     public static httpPath : string ="https://mtpapiservice.net:8445/api/v1/";
    public static partDirName:string="";

    public t_goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
    public goodss: Goods[]=new Array();//ไว้สำหรับนำมาแสดง
    public goods: Goods = new Goods();//ไว้สำหรับนำ เพิ่ม หรือ แก้ไข


    public static mr_unit : number=1;
    public static mr_itemType : number=2;
    public static mr_dept : number=3;
    public static mr_country :number=4;
    public static mr_supType :number=5;
    public static mr_cusType :number=6;
    public static mr_empLevel :number=7;

    public static ps_company :number=1;
    public static ps_cus :number=2;
    public static ps_sup :number=3;
    public static ps_emp :number=4;



//     constructor(private goodsService: GoodsService){

//     }
//     ngOnInit(): void {
//         this.getGoods();
//     }

//     private getGoods(){
//         this.goodsService.getGoodsList().subscribe(data => {
//             this.t_goodss=data;
//         });
//       }

// public setVisitCount(id : number=0,isCount : number =0)
//   {

//     this.goods.countVisits=isCount +1;

//     this.goodsService.updateVisits(id, this.goods).subscribe( data =>{

//       // alert(this.goods.countVisits);
         
//       console.log(data);
//       //  this.getGoods();//ย้อนกลับมาหน้าตัวเอง
//     }
//     , error => console.log(error));
//   }

}