import { GlobalConstants } from '../common/global-constants';
export class Goods {
    id: number =0;
    namePoint: string ="";
    imgPath1: string ="";
    imgPath2: string ="";
    desc1: string ="";
    desc2: string ="";
    groupId: number =0;
    groupIdRef1: number =0;
    groupIdRef2: number =0;
    groupIdRef3: number =0;
    href1: string ="";
    href2: string ="";
    href3: string ="";
    orderNo: number=0;
    isEnable:number=0;
    createDate: string="";
    modifyDate: string="";


    isSwap: boolean =false;
    isFirst: boolean =false;
    isShowB1: boolean=false;
    isShowB2: boolean=false;
    isShowB3: boolean=false;
    isShowB4: boolean=false;

    txtSelector :string="";
    idSelector :string="";
    imgPathTemp1: string ="";
    imgPathTemp2: string ="";

    isNotShowAddition : boolean =false;
    count: number=0;
    countVisits: number=0;

     getImgPathTemp1() : string{
        alert (this.imgPathTemp1=GlobalConstants.httpPath + this.imgPath1);
        return this.imgPathTemp1=GlobalConstants.httpPath + this.imgPath1;
    }
}