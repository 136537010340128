import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-footerbar',
  templateUrl: './footerbar.component.html',
  styleUrls: ['./footerbar.component.css']
})


export class FooterbarComponent implements OnInit {

  logoPath : string ="";
  constructor() { }

  ngOnInit(): void {
    this.logoPath=GlobalConstants.httpPath + "files/logo.png";
  }

}
