import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';
import { c_masterRef } from '../app.class/c_masterref';
import { map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class s_masterRef {

  private baseURL = "";
  private baseVisitURL = GlobalConstants.httpPath + "goods_count";
  private person : any;
  tP : String ="masterref";

  constructor(private httpClient: HttpClient) { 
    this.baseURL = GlobalConstants.httpPath + this.tP;
  }
  
  getDataList(id? :number,mr? : c_masterRef): Observable<c_masterRef[]>{
    this.person= this.httpClient.get(this.baseURL)
    .pipe(map((response: any) => response.json()));
    
    if (id > 0){
      // this.baseURL = GlobalConstants.httpPath + this.tP +"/masterArea?masterarea="+id
      this.baseURL = GlobalConstants.httpPath + this.tP +"/allkey?id="+ mr.id + "&keycode=" + mr.keycode + "&keyname=" + mr.keyname + "&masterarea=" + id;
    }
    // alert(this.baseURL); //idkeycodekeyname?id=0&keycode=e&keyname=e
    return this.httpClient.get<c_masterRef[]>(`${this.baseURL}`);
  }

  createData(data: c_masterRef): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.post(`${this.baseURL}`, data);
  }

  getDataById(id: number): Observable<c_masterRef>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.get<c_masterRef>(`${this.baseURL}/${id}`);
  }

  updateData(id: number, data: c_masterRef): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.put(`${this.baseURL}/${id}`, data);
  }

  updateVisits(id: number, data: c_masterRef): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.put(`${this.baseVisitURL}/${id}`, data);
  }

  deleteData(id: number): Observable<Object>{
    this.baseURL = GlobalConstants.httpPath + this.tP
    return this.httpClient.delete(`${this.baseURL}/${id}`);
  }
}

