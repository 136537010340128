import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenubarComponent } from './app.components/menubar/menubar.component';
import { FooterbarComponent } from './app.components/footerbar/footerbar.component';
import { HttpClientModule } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms';

import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxImgZoomModule  } from 'ngx-img-zoom';
import { LoginComponent } from './app.components/login/login.component';
import { RegisterComponent } from './app.components/register/register.component';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { FrmMasterComponent } from './app.components/frm-master/frm-master.component';
import { FrmPersonsComponent } from './app.components/frm-persons/frm-persons.component';
import { FrmPartItemsComponent } from './app.components/frm-part-items/frm-part-items.component';


@NgModule({
  declarations: [
    AppComponent,
    MenubarComponent,
    FooterbarComponent,
    UploadFilesComponent,
    LoginComponent,
    RegisterComponent,
    FrmMasterComponent,
    FrmPersonsComponent,
    FrmPartItemsComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgxImgZoomModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},authInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
