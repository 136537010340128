import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UploadFilesComponent } from './app.components/upload-files/upload-files.component';
import { RegisterComponent } from './app.components/register/register.component';
import { LoginComponent } from './app.components/login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { FrmMasterComponent } from './app.components/frm-master/frm-master.component';
import { FrmPersonsComponent } from './app.components/frm-persons/frm-persons.component';
import { FrmPartItemsComponent } from './app.components/frm-part-items/frm-part-items.component';


//app-add-edit  questionnaire-component
const routes: Routes = [
  { path: '',  redirectTo: 'login', pathMatch: 'full'},
  { path: 'app-upload-files',component:UploadFilesComponent},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'frm-master-component',component:FrmMasterComponent},
  { path: 'frm-master-component/:id/:name',component:FrmMasterComponent},
  { path: 'frm-persons-component',component:FrmPersonsComponent},
  { path: 'frm-persons-component/:id/:name',component:FrmPersonsComponent},
  { path: 'frm-part-items-component',component:FrmPartItemsComponent},
  { path: 'frm-part-items-component/:id/:name',component:FrmPartItemsComponent},
];


@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),RouterModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }