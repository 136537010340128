import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import{ GlobalConstants } from '../../common/global-constants';
import { TokenStorageService } from 'src/app/_services/token-storage.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.css','../../common/global-css.css','../../common/global-css-mobile.css']
})
export class MenubarComponent implements OnInit {
  companyName: string ="";
  firstPage:  string="";
  aboutUs:  string="";
  ourBusiness:  string="";
  quality : string="";
  productAndService:  string="";
  newsAndEvent:  string="";
  contactUs:  string="";
  language: string="";
  systems: string ="";
  goodsView: string="";
  workwithus:string="";

  test : string="";
  test2 : string="";
  role1 : boolean= false;
  role2 : boolean= false;
  logInOut : boolean=true;

  logoPath : string ="";
  bgPath : string ="";

  private roles: string[] = [];
  isLoggedIn: boolean = false;
  showAdminBoard: boolean = false;
  showModeratorBoard: boolean = false;
  username: string | undefined;

  mr_unit : number=0;
  mr_itemType : number=0;
  mr_dept : number=0;
  mr_country :number=0;
  mr_supType :number=0;
  mr_cusType :number=0;
  mr_empLevel :number=0;


  ps_company :number=1;
  ps_cus :number=1;
  ps_sup :number=1;
  ps_emp :number=1;
  

  constructor(private tokenStorageService: TokenStorageService,private route: ActivatedRoute, private router: Router) { 
    this.role1 = !!this.tokenStorageService.getToken();
    this.role2=GlobalConstants.role2;
  }

  logout(): void {
    this.tokenStorageService.signOut();
    window.location.reload();
  }



  ngOnInit(): void {
    this.role1 = !!this.tokenStorageService.getToken();
    this.role2=GlobalConstants.role2;
    this.setLanq(GlobalConstants.lanq);

    this.logoPath=GlobalConstants.httpPath + "files/logo.png";


    this.isLoggedIn = !!this.tokenStorageService.getToken();


    this.mr_unit = GlobalConstants.mr_unit;
    this.mr_itemType = GlobalConstants.mr_itemType;
    this.mr_dept = GlobalConstants.mr_dept;
    this.mr_country =GlobalConstants.mr_country;
    this.mr_supType = GlobalConstants.mr_supType;
    this.mr_cusType = GlobalConstants.mr_cusType;
    this.mr_empLevel = GlobalConstants.mr_empLevel;

    this.ps_company = GlobalConstants.ps_company;
    this.ps_cus = GlobalConstants.ps_cus;
    this.ps_sup = GlobalConstants.ps_sup;
    this.ps_emp = GlobalConstants.ps_emp;


    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
    }
    
  }

setLanq(ty:string){
   if (ty=="th")
   { 
     this.companyName="มิตรภาพโภคภัณฑ์";
    //  this.firstPage="หน้าหลัก";
     this.aboutUs="ผู้ใช้งาน";
     this.ourBusiness="ธุรกิจในเครือ";
     this.quality="ระบบจัดการคุณภาพ";
     this.productAndService="สินค้าและบริการ";
     this.newsAndEvent="ข่าวและกิจกรรม";
     this.contactUs="ติดต่อ";
     this.language="ภาษา";
     this.systems="ระบบ";
     this.goodsView="แสดงข้อมูล";
     this.test ="ทดสอบ";
     this.test2 ="ทดสอบ2";
     if (this.role2===true){
      this.logInOut=false;
     }
     else {
      this.logInOut=true;
     }
     
     this.workwithus="ร่วมงานกับเรา";
   }
   else if (ty=="en"){
     this.companyName="Mittraphap Group";
    // this.firstPage="Main Page";
     this.aboutUs="ABOUT US";
     this.ourBusiness="OUR BUSINESS";
     this.productAndService="PRODUCTS & SERVICES";
     this.newsAndEvent="NEWS & EVENT";
     this.contactUs="CONTACT INFO";
     this.language="L";
     this.systems="Sys";
     this.goodsView="View Data";
     this.test="Test page";
     this.test2="Test2 page";
     this.workwithus="WORKWITHUS"
   }
}

  verifyLang(ty: string){

    GlobalConstants.lanq=ty;
    this.setLanq(ty);
  }


  hitAnchor1(e:any){
    console.log("Events", e);
    alert("You have clicked the anchor-1 tag");
  }
  

  
  verifyUser(Us : boolean){
    GlobalConstants.role1=Us;
    this.role1=GlobalConstants.role1;
    this.role2=GlobalConstants.role2;
  }

  exitSystem(id?:number){
    GlobalConstants.role1=false;
    GlobalConstants.role2=false;
  }
}

